import React from 'react'
import './index.less'
import { imgUrl } from '../../utils/staticUrl'
const PassFooter = () => {
  return (
    <div id="pass_footer">
      <div className="content">
        <div className="pass_footer_title">关注我们</div>
        <div className="footer_img">
          <div className="img">
            <div className="icon">
              <img className="icon_image" src={imgUrl('common/shequn.png')} />
            </div>
            <div className="title">加入社群</div>
          </div>
          <div className="img">
            <div className="icon">
              <img className="icon_image" src={imgUrl('common/gongzhonghao.png')} />
            </div>
            <div className="title">公众号</div>
          </div>

          <div className="img">
            <div className="icon">
              <img className="icon_image" src={imgUrl('common/weibo.png')} />
            </div>
            <div className="title">微博</div>
          </div>

          <div className="img">
            <div className="icon">
              <img className="icon_image" src={imgUrl('common/url.png')} />
            </div>
            <div className="title">APP下载</div>
          </div>
        </div>

        <div className="Copyright">
          <p>￮Copyright © 2022-2023 Origin of the Myth All Rights Reserved 北京神话起源科技有限公司</p>
          <p>￮京ICP备案B2-20232520</p>
        </div>
      </div>
    </div>
  )
}

export default PassFooter
