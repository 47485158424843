// import React from 'react'
// import Icon from '../Icon'

export const marketUrl = '/'
export const navs = (t, isMobile = false) => {
  const defaultNavs = [
    {
      label: t('header.Home'),
      url: '/home',
      key: '/home',
    },
    {
      label: t('header.NewNotice'),
      url: '/NewNotice',
      key: '/NewNotice',
    },
    {
      label: t('header.GameGameplay'),
      url: '/GameGameplay',
      key: '/GameGameplay',
    },
    // {
    //   label: t('header.Product'),
    //   url: '/Product',
    //   key: '/Product',
    // },
    {
      label: t('header.Wikipedia'),
      url: '/Wikipedia',
      key: '/Wikipedia',
    },
    {
      label: t('header.Club'),
      url: '/Club',
      key: '/Club',
    },
    {
      label: t('header.OnlineShop'),
      url: '/OnlineShop',
      key: '/OnlineShop',
    },
    {
      label: t('header.appTitle'),
      url: '/Shanhai',
      key: '/Shanhai',
    },
  ]
  if (isMobile) {
    const BuyAac = {
      // label: t('header.Buy_aac'),
      // url: '/buyAac',
      // key: '/buyAac',
    }
    return [...defaultNavs, BuyAac]
  }

  return defaultNavs
}
