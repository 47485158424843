import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Drawer, Menu } from 'antd'
import Animate from 'rc-animate'
import Icon from '_src/components/Icon'
import { useAppSelector } from '_src/store/hooks'
import { navs, marketUrl } from './navs'
import './index.less'
import useSafeState from '../../hooks/useSafeState'
import { IsMobile } from '_src/utils/url'
import { imgUrl } from '../../utils/staticUrl'
const PassMenu = ({ closePopup }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { activeUrl } = useAppSelector((store) => store.menu)
  const [visible, setVisible] = useState(false)
  const [show, setShow] = useState(0)

  const [openKeys, setOpenKeys] = useSafeState([marketUrl])

  const goLink = (item, type = 'pc') => {
    console.log(item, 'item')
    if (Object.prototype.hasOwnProperty.call(item, 'children')) return

    const { url = '' } = item
    type == 'mobile' && onClick()
    // 创建需要权限

    url && history.push(url)
    type = 'pc' && setShow(-1)

    if (item.key == "/Wikipedia") {
      window.location.href = 'http://wiki.wtcg.com.cn/index.php?title=%E9%A6%96%E9%A1%B5'
    }
  }

  const handleMenuClick = (item) => {
    console.log(item, 'item')
    const { key = '' } = item

    if (key && key.includes('/')) {
      goLink({ url: key }, 'mobile')
    }
  }

  const handleMenuOpen = (keys) => {
    console.log(keys, 'keys')

    const { [keys.length - 1]: last } = keys

    if (last) {
      setOpenKeys([last])
    } else {
      setOpenKeys([])
    }
  }

  const onClick = () => {
    setVisible(!visible)
  }
  const showMenuItem = (index) => {
    setShow(index)
  }
  useEffect(() => { }, [])
  return (
    <Fragment>
      <div className="mobile-menu-container">
        {visible ? (
          <Icon onClick={onClick} type="icon-closed" className="menu-icon" />
        ) : (
          <Icon onClick={onClick} type="icon-fold" className="menu-icon" />
        )}

        <Drawer onClose={onClick} placement="top" closable={false} open={visible} className="mobile-drawer-container">
          <Menu
            forceSubMenuRender={true}
            className="mobile-cust-menu-container"
            onClick={handleMenuClick}
            onOpenChange={handleMenuOpen}
            openKeys={openKeys}
            defaultOpenKeys={[marketUrl]}
            mode="inline"
            selectedKeys={activeUrl}
            items={navs(t, true)}
          />
        </Drawer>
      </div>
      {/*  */}
      <div className="pc-menu-container">
        {navs(t).map((item, index) => (
          <div
            key={index}
            onClick={() => {
              goLink(item)
              closePopup()
            }}
            onMouseEnter={() => {
              showMenuItem(index)
            }}
            onMouseLeave={() => {
              setShow(-1)
            }}
            className={classNames('pc-menu-item', {
              'inft-menu-active': activeUrl.includes(item.url),
            })}
          >
            <div className="item-title">{item.label}</div>
            {IsMobile() == true &&
              <div className="item-title_icon">
                <img className='item-title_icons' src={imgUrl('common/right.png')} />
              </div>
            }
            {item?.children && (
              <Animate transitionName="inft-fade" transitionAppear>
                {show == index && (
                  <div className="menu-item-container">
                    {item.children.map((it, ix) => (
                      <div
                        onClick={() => {
                          console.log(it, 'it')
                          goLink(it)
                        }}
                        className={classNames('menu-item-content', {
                          'inft-menu-active': activeUrl.includes(it.url),
                        })}
                        key={ix}
                      >
                        {it.label}
                      </div>
                    ))}
                  </div>
                )}
              </Animate>
            )}
          </div>
        ))}
      </div>
    </Fragment>
  )
}

export default PassMenu
