const routes = () => [
  // path: '/home/:join?/:invite_code?',携带参数方式
  /**
   * @descrition 携带参数方式
   * @params {String} 当前参数
   * @identification {?} ?为参数可选
   * @hideFooter hideFooter:true 不显示Footer
   */
  {
    path: '/home',
    component: () => import('../pages/home'),
    exact: true,
    children: [
      //首页
      {
        path: '/home/index',
        component: () => import('../pages/home/index'),
        exact: true,
      },
      {
        path: '/home/details',
        component: () => import('../pages/home/details'),
        exact: true,
      },
      {
        path: '/home/homeDetails',
        component: () => import('../pages/home/homeDetails'),
        exact: true,
      },
    ],
  },

  {
    path: '/NewNotice',
    component: () => import('../pages/NewNotice'),
    exact: true,
    children: [
      //新闻公告
      {
        path: '/NewNotice/index',
        component: () => import('../pages/NewNotice/index'),
        exact: true,
      },
      //新闻公告详情
      {
        path: '/NewNotice/details',
        component: () => import('../pages/NewNotice/details'),
        exact: true,
      },
    ],
  },

  {
    path: '/GameGameplay',
    component: () => import('../pages/GameGameplay'),
    exact: true,
    children: [
      //游戏玩法
      {
        path: '/GameGameplay/index',
        component: () => import('../pages/GameGameplay/index'),
        exact: true,
      },
      // 详情
      {
        path: '/GameGameplay/details',
        component: () => import('../pages/GameGameplay/details'),
        exact: true,
      },
    ],
  },

  {
    path: '/Product',
    component: () => import('../pages/Product'),
    exact: true,
    children: [
      //商品介绍
      {
        path: '/Product/index',
        component: () => import('../pages/Product/index'),
        exact: true,
      },
    ],
  },

  {
    path: '/Wikipedia',
    component: () => import('../pages/Wikipedia'),
    exact: true,
    children: [
      //神话百科
      {
        path: '/Wikipedia/index',
        component: () => import('../pages/Wikipedia/index'),
        exact: true,
      },
      {
        path: '/Wikipedia/details',
        component: () => import('../pages/Wikipedia/details'),
        exact: true,
      },
    ],
  },

  {
    path: '/Club',
    component: () => import('../pages/Club'),
    exact: true,
    children: [
      //俱乐部
      {
        path: '/Club/index',
        component: () => import('../pages/Club/index'),
        exact: true,
      },
      {
        path: '/Club/infomationClub',
        component: () => import('../pages/Club/infomationClub'),
        exact: true,
      },
      {
        path: '/Club/list',
        component: () => import('../pages/Club/list'),
        exact: true,
      },
      {
        path: '/Club/details',
        component: () => import('../pages/Club/details'),
        exact: true,
      },
    ],
  },

  {
    path: '/OnlineShop',
    component: () => import('../pages/OnlineShop'),
    exact: true,
    children: [
      //在线商城
      {
        path: '/OnlineShop/index',
        component: () => import('../pages/OnlineShop/index'),
        exact: true,
      },
      {
        path: '/OnlineShop/details',
        component: () => import('../pages/OnlineShop/details'),
        exact: true,
      },
    ],
  },
  {
    path: '/Shanhai',
    component: () => import('../pages/Shanhai'),
    exact: true,
    children: [
      {
        path: '/Shanhai/index',
        component: () => import('../pages/Shanhai/index'),
        exact: true,
      },
    ],
  },
]
export default routes
