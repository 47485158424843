import React, { useEffect, useState } from 'react'
import i18n from 'i18next'
import { useAppSelector, useAppDispatch } from '_src/store/hooks'

import Animate from 'rc-animate'
import classnames from 'classnames'

import { setLanguage } from '_src/store/global/reducer'
import useWindowSize from '_src/hooks/useWindowSize'

import Icon from '../Icon'
import './index.less'

export const languageList = [
  { name: '中文', value: 'zh_CN' },
  { name: 'English', value: 'en_US' },
  { name: 'Español', value: 'es_ES' },
]
export const handleLanguageClick = (value, func) => {
  i18n.changeLanguage(value)
  func()
}

const LanguageSelect = () => {
  const lng = localStorage.getItem('i18nextLng') || ''

  const { lng: storeLng } = useAppSelector((store) => store.global)

  const dispatch = useAppDispatch()
  const size = useWindowSize()
  const [show, setShow] = useState(false)
  const handleShow = (flag) => {
    setShow(flag)
  }

  const handleClick = (item) => {
    const { value = 'en_US' } = item
    handleLanguageClick(value, () => handleShow(false))
  }

  useEffect(() => {
    dispatch(setLanguage(lng))
  }, [lng])

  return (
    <div
      className="language_select"
      onMouseOver={() => {
        size > 1200 && handleShow(true)
      }}
      onMouseLeave={() => {
        size > 1200 && handleShow(false)
      }}
      onClick={() => {
        size < 1200 && handleShow(!show)
      }}
    >
      <div className="language_input">
        <Icon type="icon-menu-language" className="pc-language-icon" />
      </div>

      <div className="mobile-language-icon">
        <Icon type="icon-menu-language" />
      </div>

      <Animate transitionName="inft-fade" transitionAppear>
        {show && (
          <div className="language-container">
            {languageList.map((item, index) => (
              <div
                key={index}
                className={classnames('language-item', {
                  isActive: item.value == storeLng,
                })}
                onClick={() => handleClick(item)}
              >
                {item.name}
              </div>
            ))}
          </div>
        )}
      </Animate>
    </div>
  )
}

export default LanguageSelect
