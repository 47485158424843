import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import './index.less'
import LanguageSelect from '../LanguageSelect'
import PassMenu from '../Menu'
import { IsMobile } from '_src/utils/url'
import { imgUrl } from '_src/utils/staticUrl'
import { Popup } from 'antd-mobile'
const PassHeader = ({ className }) => {

  const [visible4, setVisible4] = useState(false)
  const history = useHistory()
  const handleHome = () => {
    history.push({
      pathname: '/home',
    })
  }

  return (
    <div id={classNames('pass_header', className)}>
      {IsMobile() == true &&
        <div className='header_con'>
          <img src={imgUrl('common/logo.png')} className='header_con_img'></img>
          <img src={imgUrl('common/common1.png')} className='header_con_img1' onClick={() => {
            setVisible4(true)
          }}></img>
        </div>
      }

      {IsMobile() != true &&
        <div className='header_con'>
          <div className="header_left">
            <div className="logo" onClick={handleHome}></div>
          </div>
          <PassMenu closePopup={() => {
            setVisible4(false)
          }} />
          <div className="header_right">
            {!!0 && <LanguageSelect />}
          </div>
        </div>
      }

      <Popup
        visible={visible4}
        onMaskClick={() => {
          setVisible4(false)
        }}
        onClose={() => {
          setVisible4(false)
        }}
        showCloseButton={true}
        position='right'
        bodyStyle={{ width: '60vw', background: '#F6EAD8' }}
      >
        <PassMenu closePopup={() => {
          setVisible4(false)
        }} />
      </Popup>


    </div>
  )
}

export default PassHeader
